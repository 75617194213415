import React from 'react'
import Text from '@component/Text/Text'

interface IProps {
    className?: string
    textClassName?: string
    text?: string
}

const EmptyComponent: React.FC<IProps> = ({className, text, textClassName}) => {
    return (
        <div className={`flex justify-center items-center bg-white dark:bg-bg_dark_white01 ${className}`}>
            <Text className={`text-body1 text-gray02 dark:text-dark_gray02 ${textClassName}`}>{text}</Text>
        </div>
    )
}

export default EmptyComponent
