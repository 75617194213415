import React, {useEffect, useMemo, useState} from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import Spinner from '@component/Spinner/Spinner'

interface IProps {
    text?: string
    className?: string
    onClick?: () => void
    disabled?: boolean
    renderLeft?: JSX.Element | JSX.Element[]
    renderRight?: JSX.Element | JSX.Element[]
    isLoading?: boolean
    dataCy?: string
    againSecond?: number
    textClassName?: string
}

const MoreButton: React.FC<IProps> = ({
    text,
    className,
    onClick,
    disabled = false,
    renderLeft,
    renderRight,
    isLoading = false,
    dataCy,
    againSecond,
    textClassName,
}) => {
    const {t} = useTranslation()
    const [isDelay, setIsDelay] = useState(false)

    const buttonClass = useMemo(() => {
        if (disabled) {
            return 'bg-gray07 dark:bg-dark_gray07 text-gray04 dark:text-dark_gray04 cursor-default'
        }
        return 'text-gray01 dark:text-dark_gray01 shadow-normal hover:bg-gray09 dark:hover:bg-bg_dark_white02 active:bg-gray07 dark:active:bg-dark_gray07 active:shadow-none'
    }, [disabled])

    useEffect(() => {
        if (isDelay === true) {
            setTimeout(() => {
                setIsDelay(false)
            }, againSecond)
        }
    }, [isDelay, againSecond])

    return (
        <>
            {isLoading !== true && (
                <button
                    data-cy={dataCy}
                    className={`${className} flex justify-center items-center min-w-[250px] rounded-[30px] gap-x-[7px] bg-white dark:bg-bg_dark_white03 min-h-[60px] text-ti2 ${buttonClass}`}
                    onClick={() => {
                        if (againSecond && !isDelay) {
                            setIsDelay(true)
                            onClick()
                        } else if (!againSecond) {
                            onClick()
                        }
                    }}
                    disabled={disabled}>
                    {renderLeft}
                    <Text className={`${!disabled && 'text-gray01 dark:text-dark_gray01'} ${textClassName}`}>
                        {text ?? t('component.loadMore.text')}
                    </Text>
                    {renderRight}
                </button>
            )}
            {isLoading === true && (
                <div className={'flex justify-center min-h-[60px] items-center'}>
                    <Spinner visible={true} size={'large'} />
                </div>
            )}
        </>
    )
}

export default MoreButton
