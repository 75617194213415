import React, {useContext, useReducer, useRef} from 'react'
import Text from '@component/Text/Text'
import WizImage from '@component/images/WizImage'
import icon_sns_share_flip from '@image/icon_sns_share_flip.png'
import FrontViewContainer from '@feature/common/components/PortfolioShareModal/card/FrontViewContainer'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import BackViewContainer from '@feature/common/components/PortfolioShareModal/card/BackViewContainer'
import {useTranslation} from 'next-i18next'
import {isMobile} from 'react-device-detect'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

interface IProps {
    className?: string
}
const GRADIENT_COLOR_GREEN = '#B9FFE1'
const GRADIENT_COLOR_BLUE = '#EAEEFF90'
const initialState = {
    position: {x: 0, y: 0},
    prev: 0,
    calc: 0,
    isMove: false,
    isOnMouse: false,
    clickPointOffset: 0,
    gradientColor: GRADIENT_COLOR_GREEN,
    gradientPosition: 0,
    bgImage: "bg-[url('/images/img_share_bg.png')]",
    bgCardImage: {
        front: "bg-[url('/images/img_share_card_bg.png')]",
        back: "bg-[url('/images/img_share_card_bg_back.png')]",
    },
}
const ROTATION_RANGE = 32.5
const HALF_ROTATION_RANGE = ROTATION_RANGE / 2

const FlipCardContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {setViewPage, themeColor, viewPage, isSwiping, setIsSwiping} = useContext(SnsShareContext)

    const cardWrapperRef = useRef<HTMLDivElement>()

    const [state, dispatch] = useReducer(reducer, initialState)
    const {position, prev, calc, isMove, clickPointOffset, gradientColor, gradientPosition, bgImage, bgCardImage} =
        state

    function reducer(state, action) {
        switch (action.type) {
            case 'field_update':
                return {...state, [action.field]: action.value}
        }
    }

    const onReset = () => {
        if (!cardWrapperRef.current.style.transition) {
            cardWrapperRef.current.style.transition = 'all 0.3s ease-out'
        }
        cardWrapperRef.current.style.transform = `perspective(850px) rotateX(${0}deg) rotateY(${0}deg)`
        dispatch({type: 'field_update', field: 'gradientPosition', value: 0})
    }

    const onMouseEnter = () => {
        if (cardWrapperRef.current.style.transition) {
            setTimeout(() => {
                cardWrapperRef.current.style.transition = ''
            }, 200)
        }
    }

    const onMouseDownEvent = e => {
        if (isSwiping) return
        if (!isMove) {
            dispatch({type: 'field_update', field: 'isMove', value: true})
        }
        const positionX = (isMobile ? e.touches?.item(0)?.screenX : e.clientX) ?? e.clientX
        dispatch({type: 'field_update', field: 'clickPointOffset', value: positionX})
        dispatch({type: 'field_update', field: 'prev', value: prev + calc})
    }

    const onMouseUpEvent = e => {
        onReset()

        if (isSwiping) return

        const positionX = (isMobile ? e.touches?.item(0)?.screenX : e.clientX) ?? e.clientX
        if (clickPointOffset !== positionX) {
            dispatch({
                type: 'field_update',
                field: 'prev',
                value: Math.round(Math.trunc(calc + prev) / 180) * 180,
            })
            dispatch({type: 'field_update', field: 'calc', value: 0})

            const isBack = Math.abs((Math.round(Math.trunc(calc + prev) / 180) * 180) % 360) === 0
            if (isBack) {
                setViewPage('front')
            } else {
                setViewPage('back')
            }
        }
        dispatch({type: 'field_update', field: 'isMove', value: false})
    }

    const onMouseMoveEvent = e => {
        if (isMove) {
            const positionX = (isMobile ? e.touches?.item(0)?.screenX : e.clientX) ?? e.clientX
            dispatch({type: 'field_update', field: 'calc', value: (clickPointOffset - positionX) / 2})
        } else {
            if (!cardWrapperRef.current || isMobile) return
            const rect = cardWrapperRef.current.getBoundingClientRect()

            const width = rect.width
            const height = rect.height

            const mouseX = e.clientX - rect.left
            const mouseY = e.clientY - rect.top
            const gPositionX = (mouseX - width / 2) * -1
            const gPositionY = mouseY - height / 2
            const rX = (mouseY * ROTATION_RANGE) / height - HALF_ROTATION_RANGE
            const rY = ((mouseX * ROTATION_RANGE) / width - HALF_ROTATION_RANGE) * -1

            dispatch({type: 'field_update', field: 'position', value: {x: rX, y: rY}})

            if (gPositionX < 0) {
                dispatch({type: 'field_update', field: 'gradientPosition', value: gPositionX / 5 - gPositionY / 5})
            } else {
                dispatch({type: 'field_update', field: 'gradientPosition', value: gPositionX - gPositionY / 5})
            }
        }
    }

    useIsomorphicLayoutEffect(() => {
        if (themeColor === 'primary') {
            dispatch({type: 'field_update', field: 'gradientColor', value: GRADIENT_COLOR_GREEN})
            dispatch({type: 'field_update', field: 'bgImage', value: "bg-[url('/images/img_share_bg.png')]"})
            dispatch({
                type: 'field_update',
                field: 'bgCardImage',
                value: {
                    front: "bg-[url('/images/img_share_card_bg.png')]",
                    back: "bg-[url('/images/img_share_card_bg_back.png')]",
                },
            })
        } else if (themeColor === 'blue') {
            dispatch({type: 'field_update', field: 'gradientColor', value: GRADIENT_COLOR_BLUE})
            dispatch({type: 'field_update', field: 'bgImage', value: "bg-[url('/images/img_share_bg_blue.png')]"})
            dispatch({
                type: 'field_update',
                field: 'bgCardImage',
                value: {
                    front: "bg-[url('/images/img_share_card_bg_blue.png')]",
                    back: "bg-[url('/images/img_share_card_bg_blue_back.png')]",
                },
            })
        }
    }, [themeColor])

    const onClickFlip = (e?: any, isButton: boolean = false) => {
        if (isSwiping) return

        const switchViewPage = () => {
            const isFront = Math.abs(prev % 360) === 0
            if (isFront) {
                setViewPage('back')
            } else {
                setViewPage('front')
            }
        }
        if (!isSwiping) {
            setIsSwiping(true)
            const positionX = (isMobile ? e.touches?.item(0)?.screenX : e.clientX) ?? e.clientX
            if (clickPointOffset === positionX) {
                const rect = cardWrapperRef.current.getBoundingClientRect()
                const width = rect.width

                const rX = (positionX - rect.left) / width
                const rotateY = 180 * (rX > 0.5 ? 1 : -1)

                dispatch({type: 'field_update', field: 'prev', value: prev + rotateY})
                switchViewPage()
            }

            if (isButton) {
                dispatch({type: 'field_update', field: 'prev', value: prev >= 0 ? prev - 180 : prev + 180})
                switchViewPage()
            }

            setTimeout(() => {
                setIsSwiping(false)
                cardWrapperRef.current.style.transition = ''
            }, 1000)
        }
    }

    return (
        <div
            className={`relative h-full py-[35px] bg-cover bg-no-repeat w-[450px] md:w-full bg-primary ${bgImage} ${className}`}>
            <div
                ref={cardWrapperRef}
                style={{
                    transition: 'all 0.3s ease-out',
                    transform: `perspective(850px) rotateX(${position.x}deg) rotateY(${position.y}deg)`,
                    transformStyle: `preserve-3d`,
                    WebkitTapHighlightColor: 'transparent',
                    userSelect: 'none',
                }}
                onClick={onClickFlip}
                onMouseDown={onMouseDownEvent}
                onMouseLeave={onMouseUpEvent}
                onMouseUp={onMouseUpEvent}
                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMoveEvent}
                onTouchStart={e => {
                    isMobile && onMouseDownEvent(e)
                }}
                onTouchCancel={e => {
                    isMobile && onMouseUpEvent(e)
                }}
                onTouchEnd={e => {
                    isMobile && onMouseUpEvent(e)
                }}
                onTouchMove={e => {
                    isMobile && isMove && onMouseMoveEvent(e)
                }}
                className={`${isMove ? 'cursor-grabbing' : 'cursor-pointer'}`}>
                <div
                    style={{
                        transform: `rotateY(${prev + calc}deg)`,
                        transformStyle: 'preserve-3d',
                        backgroundRepeat: 'no-repeat',
                        background: `linear-gradient(105deg, transparent 40%, ${gradientColor} 50%, transparent 60%) ${gradientPosition}% 0 / 150% 150%`,
                    }}
                    className={`relative mx-auto w-[300px] h-[500px] rounded-[20px] border-[1.5px] border-white/50 ${
                        !isMove && isSwiping && 'transition-all ease-out duration-1000'
                    }`}>
                    <FrontViewContainer cardBackgroundImage={bgCardImage?.front} />
                    <BackViewContainer cardBackgroundImage={bgCardImage?.back} />
                </div>
            </div>

            <div
                style={{WebkitTapHighlightColor: 'transparent'}}
                className={'flex justify-center items-center space-x-[10px] mt-[10px] cursor-pointer'}
                onClick={e => onClickFlip(e, true)}>
                <Text className={'text-body_comm text-white hidden md:block'}>
                    {t('modal.snsShare.card.swipe.text')}
                </Text>
                <Text className={'text-body_comm text-white md:hidden'}>{t('modal.snsShare.card.click.text')}</Text>
                <WizImage src={icon_sns_share_flip} width={25} />
            </div>
        </div>
    )
}

export default FlipCardContainer
