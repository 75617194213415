import React from 'react'
import Text from '@component/Text/Text'
import IconBack from '@svg/gnb/ic_mo_back.svg'
import {useRouter} from 'next/router'

interface IProps {
    className?: string
    text: string
}

const HistoryBackTitle: React.FC<IProps> = ({className, text}) => {
    const router = useRouter()
    return (
        <div className={`flex items-center gap-x-[5px] px-[15px] py-[10px] ${className}`}>
            <IconBack
                onClick={() => {
                    router?.back()
                }}
                className={'w-[20px] fill-gray01 dark:fill-dark_gray01 cursor-pointer '}
            />
            <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>{text}</Text>
        </div>
    )
}

export default HistoryBackTitle
