import React from 'react'
import IconUser from '@svg/common/ic_user.svg'
import Text from '@component/Text/Text'
import useFormatNumber from '@hook/useFormatNumber'

interface IProps {
    copier?: number
    maxCopier?: number
    className?: string
}

const CopierCount: React.FC<IProps> = ({className, copier = 0, maxCopier = 0}) => {
    const {displayNumber} = useFormatNumber()

    return (
        <div className={`flex items-center gap-x-[5px] ${className}`}>
            <IconUser className={'w-[14px] fill-gray01 dark:fill-dark_gray01'} />
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                {displayNumber(copier)}/{displayNumber(maxCopier)}
            </Text>
        </div>
    )
}

export default CopierCount
